<template>
  <base-section
    id="organization"
    class="white"
    space="36"
  >
    <v-container>
      <v-slide-group>
        <template v-for="data in organization">
          <v-slide-item
            :key="data.id"
            class="align-self-center"
          >
            <base-img
              :src="require(`@/assets/organization-${data.id}.jpg`)"
              color="grey"
              contain
              height="100"
              width="128"
              alt=""
              @click="URL(data.link)"
            />
          </v-slide-item>

          <v-responsive
            :key="`divider-${data.id}`"
            class="text-center"
            height="56"
            width="48"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionOrganization',
    data: () => ({
        // organization: [
        //   { id: 1, link: 'https://www.ojk.go.id' },
        //   { id: 2, link: 'https://asippindo.or.id/' },
        // ],
        organization: []
    }),
    methods: {
      URL (id) {
        window.open(id, '_blank')
      }
    }
  }
</script>
